

import { useMemo } from "react";
import { BsBoxSeam } from "react-icons/bs";

import { Column, Row } from "common/components";
import Card, { CardProps } from "common/components/cards/Card";
import { Product } from "product/product.entities";

import styles from "./styles.module.scss";


export default function ProductCard({
  className, value, onClick, tag, children, ...rest
}: ProductCardProps) {
  const classes = useMemo(() =>
    [styles.productCard, className].join(" "),
    [className]);

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classes}
      onClick={onClick}
      {...rest}
    >
      <Column fill>
        <Row>
          <h4>{value.name}</h4>
          {tag}
        </Row>
        <i>{value.description}</i>
        {children}
        <footer>{value.price}</footer>
      </Column>
      <div className={styles.productIcon}><BsBoxSeam /></div>
      {value.image?.small &&
        <img
          className={styles.productImage}
          data-available={value.available}
          src={value.image.small.url}
          onError={(event: any) => event.target.style.display = 'none'} />}
    </Card>
  );
}

export type ProductCardProps = CardProps & {
  value: Product,
  tag?: JSX.Element,
};